/* Prevent click/focus visual changes */
.clickable-box {
  -webkit-tap-highlight-color: transparent; /* Removes tap highlight on mobile */
  outline: none; /* Removes focus outline */
  user-select: none; /* Prevents text selection on click */
}

.clickable-box:active {
  background-color: inherit; /* Prevents dimming when clicked */
}

.membership-container {
  display: flex;
  gap: 16px;
  flex-direction: row;
}

@media (max-width: 768px) {
  .membership-container {
    flex-direction: column;
  }
}

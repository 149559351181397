@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
